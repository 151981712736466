import { TweenLite } from 'gsap/TweenMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { getPosition } from './helpers';

export default class Accordion {
	constructor(el, group) {
		this.el = el;
		this.group = group;

		this.trigger = this.el.querySelector('.accordion__trigger');
		this.content = this.el.querySelector('.accordion__content');
		this.container = this.el.querySelector('.accordion__content__container');
		this.closeBtn = this.el.querySelector('.accordion__close-btn');

		this.isOpen = false;

		this.init();
		this.events();
	}

	init() {
		if (this.el.classList.contains('open-on-load')) {
			this.el.classList.add('is-open');
			this.setContentHeight();
			this.isOpen = true;
		}
	}

	events() {
		this.trigger.addEventListener('click', this.toggle.bind(this));
		window.addEventListener('resize', this.resizeHandle.bind(this));
		this.closeBtn.addEventListener('click', this.close.bind(this));
	}

	// toggle accordion
	toggle() {
		this.isOpen ? this.close() : this.open();
		// setTimeout(() => {
		// 	App.stickySidebar.updateSticky();
		// }, 700);
	}

	// open accordion=
	open() {
		// this.group.closeAll();
		this.el.classList.add('is-open');
		this.setContentHeight();
		this.isOpen = true;
		App.stickySidebar.updateSticky();

		requestAnimationFrame(() => {
			const y = getPosition(this.el).y - 100;
			TweenLite.to(window, 1, { ease: Power3.easeInOut, scrollTo: { y: y, autoKill: false } });
		});
	}

	// close accordion
	close() {
		this.el.classList.remove('is-open');
		this.content.removeAttribute('style');
		this.isOpen = false;
		App.stickySidebar.updateSticky();

		requestAnimationFrame(() => {
			const y = getPosition(this.el).y - 100;
			TweenLite.to(window, 1, { ease: Power3.easeInOut, scrollTo: { y: y, autoKill: false } });
		});
	}

	// get the height of accordion content container
	containerHeight() {
		return `${this.container.getBoundingClientRect().height}px`;
	}

	// set content height to height of content container
	setContentHeight() {
		this.content.style.height = this.containerHeight();
	}

	resizeHandle() {
		if (this.isOpen) {
			this.setContentHeight();
		}
	}

}