'use strict';

import 'babel-polyfill';
import { getPosition } from './modules/helpers';
import { TweenLite, TimelineMax } from 'gsap/TweenMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Header from './modules/header';
import Home from './modules/home';
import AccordionGroup from './modules/accordiongroup';
import Staff from './modules/staff';
import Milestones from './modules/milestones';
import Sidebar from './modules/sidebar';
import StickySidebar from 'sticky-sidebar';

class App {

	constructor() {
		this.body = document.querySelector('body');
		this.accordionGroups = [...document.querySelectorAll('.accordions__container')];
		this.staffMembers = [...document.querySelectorAll('.member')];
		this.milestones = document.querySelector('.milestones');
		this.sidebar = document.querySelector('.sidebar');

		this.recipientsViewMore = document.querySelector('.recipients__view-more-btn');

		this.StaffMembers = [];

		this.signup = document.getElementById('mc-embedded-subscribe-form');

		this.init();
	}

	init() {
		this.Header = new Header();

		if (this.body.classList.contains('page-template-page-home')) {
			this.Home = new Home();
		}

		if (this.accordionGroups) {
			this.accordionGroups.forEach(el => new AccordionGroup(el, this));
		}

		if (this.staffMembers) {
			this.staffMembers.forEach(el => this.StaffMembers.push(new Staff(el)));
		}

		if (this.milestones) {
			this.Milestones = new Milestones(this.milestones);
		}

		if (this.sidebar) {
			new Sidebar(this.sidebar);

			this.stickySidebar = new StickySidebar('#sidebar', {
				topSpacing: 100,
				bottomSpacing: 50,
				containerSelector: '.page-container',
				innerWrapperSelector: '.sidebar__main-nav',
				resizeSensor: true,
				stickyClass: 'is-affixed',
				minWidth: 0
			});

		}

		if (this.recipientsViewMore) {
			const recipientsCon = document.querySelector('.recipients');

			if (recipientsCon) {
				const viewMore = this.recipientsViewMore.getAttribute('data-view-more');
				const viewLess = this.recipientsViewMore.getAttribute('data-view-less');

				this.recipientsViewMore.addEventListener('click', () => {
					if (recipientsCon.classList.contains('expanded')) {
						recipientsCon.classList.remove('expanded');
						this.recipientsViewMore.innerHTML = viewMore;
						const y = getPosition(recipientsCon).y - 30;
						TweenLite.set(window, { ease: Power3.easeInOut, scrollTo: { y: y, autoKill: false } });
					} else {
						recipientsCon.classList.add('expanded');
						this.recipientsViewMore.innerHTML = viewLess;
					}
				});
			}
		}

		if (this.signup) {
			const consent = this.signup.querySelector('.consent');

			if (consent) {
				const checkbox = consent.querySelector('input[type="checkbox"]');
				const consentLabel = consent.querySelector('label');

				this.signup.addEventListener('submit', e => {

					if (checkbox.checked) {
						consent.classList.remove('error');
					} else {
						consent.classList.add('error');
					}
				});
			}
		}
	}
}

window.App = new App();