import { TweenLite, TimelineMax } from 'gsap/TweenMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { getPosition, scrollTop, viewport, getClosest } from './helpers';
import throttle from 'lodash.throttle';

export default class Header {

	constructor() {

		this.header = document.querySelector('.header');
		this.searchBar = document.querySelector('.search-bar');

		this.searchBarTrigger = document.querySelector('.search-btn');
		this.searchBarClose = document.querySelector('.search-bar__close');

		this.mobileNav = document.querySelector('.mobile-nav');
		this.mobileNavTrigger = document.querySelector('.mobile-nav-trigger');
		this.mobileNavBackdrop = document.querySelector('.mobile-nav-backdrop');
		this.mobileNavCloseBtn = document.querySelector('.mobile-nav__close');
		this.mobileNavMenu = this.mobileNav.querySelector('.mobile-nav__menu');

		this.mobileSecondNav = [...this.mobileNav.querySelectorAll('.mobile-nav__menu > ul > li > .sub-menu > li.menu-item-has-children')];
		this.mobilvNavBack = [...this.mobileNav.querySelectorAll('.mobile-nav__menu > ul > li > .sub-menu > li.menu-item-has-children > .sub-menu > li:first-of-type > .nav-back')];

		this.init();
		this.events();
	}

	init() {
		this.scrollHandle();

		const hash = location.hash;

		if (hash) {
			const hashTrimmed = hash.substr(1);

			if (hashTrimmed === 'subscribe') {
				const subSection = document.querySelector('.subscribe-section');

				if (subSection) {
					const y = getPosition(subSection).y - 100;
					TweenLite.to(window, 1, { ease: Power3.easeInOut, scrollTo: { y: y, autoKill: false } });
				}
			}
		}

	}

	events() {
		this.scrollHandle = throttle(this.scrollHandle, 16).bind(this);
		window.addEventListener('scroll', this.scrollHandle);

		document.addEventListener('click', e => {
			if (this.searchBar.classList.contains('active')) {
				console.log('active')
				if (!getClosest(e.target, '.search-bar')
					&& !getClosest(e.target, '.search-btn')) {
					this.searchBar.classList.remove('active');
				}
			}
		});

		if (this.searchBarTrigger) {
			this.searchBarTrigger.addEventListener('click', e => {
				this.searchBar.classList.add('active');
			});
		}

		if (this.searchBarClose) {
			this.searchBarClose.addEventListener('click', e => {
				this.searchBar.classList.remove('active');
			});
		}

		if (this.mobileNavTrigger && this.mobileNav) {
			this.mobileNavTrigger.addEventListener('click', e => {
				this.mobileNav.classList.add('open');
				this.mobileNavBackdrop.classList.add('open');
			});
		}

		if (this.mobileNav) {
			if (this.mobileNavTrigger) {
				this.mobileNavTrigger.addEventListener('click', e => {
					this.openMobileNav();
				});
			}

			if (this.mobileNavBackdrop) {
				this.mobileNavBackdrop.addEventListener('click', e => {
					this.closeMobileNav();
				});
			}

			if (this.mobileNavCloseBtn) {
				this.mobileNavCloseBtn.addEventListener('click', e => {
					this.closeMobileNav();
				});
			}
		}

		if (this.mobileSecondNav.length > 0) {
			this.mobileSecondNav.forEach(el => {
				const navTo = el.childNodes[2];
				const subMenu = el.childNodes[4];

				navTo.addEventListener('click', e => {
					subMenu.classList.add('active');
					this.mobileNavMenu.classList.add('hide');

					TweenLite.to(this.mobileNav, 0.8, {
						ease: Power3.easeInOut,
						scrollTo: { y: 0, autoKill: false },
					});
				});
			});
		}

		if (this.mobilvNavBack.length > 0) {
			this.mobilvNavBack.forEach(el => {
				el.addEventListener('click', e => {
					const subMenu = getClosest(el, '.sub-menu');

					if (subMenu) {
						this.mobileNavMenu.classList.remove('hide');
						subMenu.classList.remove('active');
					}
				});
			});
		}

	}

	openMobileNav() {
		this.mobileNav.classList.add('open');
		this.mobileNavBackdrop.classList.add('open');
	}

	closeMobileNav() {
		this.mobileNav.classList.remove('open');
		this.mobileNavBackdrop.classList.remove('open');
	}

	scrollHandle() {
		const st = scrollTop();

		if (st > 100) {
			this.header.classList.add('scrolled');
			this.searchBar.classList.add('scrolled');
		} else {
			this.header.classList.remove('scrolled');
			this.searchBar.classList.remove('scrolled');
		}
	}


}