/**
 * Get the closest matching element up the DOM tree.
 * @private
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 */
 export function getClosest ( elem, selector ) {

  // Element.matches() polyfill
  if (!Element.prototype.matches) {
      Element.prototype.matches =
          Element.prototype.matchesSelector ||
          Element.prototype.mozMatchesSelector ||
          Element.prototype.msMatchesSelector ||
          Element.prototype.oMatchesSelector ||
          Element.prototype.webkitMatchesSelector ||
          function(s) {
              var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                  i = matches.length;
              while (--i >= 0 && matches.item(i) !== this) {}
              return i > -1;
          };
  }

  // Get closest match
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if ( elem.matches( selector ) ) return elem;
  }

  return null;
}


/**
* Get accurate viewport width and height
* Example: viewport().width
*/
export function viewport() {
	let e = window;
	let a = "inner";
	if (!( "innerWidth" in window )) {
		a = "client";
		e = document.documentElement || document.body;
	}
	return { width : e[ a+"Width" ] , height : e[ a+"Height" ] }
}

/**
* Get the height of the entire page
* Compatible with most (if not all) browsers
*/

export function pageHeight() {
	let body = document.body;
	let html = document.documentElement;
	return Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
}

/**
* Get the current scrollTop / pageYOffset value
* Compatible with most (if not all) browsers
*/
export function scrollTop() {
	return document.documentElement.scrollTop || window.pageYOffset;
}

/**
* returns the absolute position of an element regardless of position/float issues
* @param {HTMLElement} el - element to return position for 
* @returns {object} { x: num, y: num }
*/
export function getPosition(el) {

	let x = 0;
	let y = 0;

	do {
		x += el.offsetLeft || 0;
		y += el.offsetTop || 0;
		el = el.offsetParent;
	} while (el);

	return { x: parseInt(x, 10), y: parseInt(y, 10) };
}

/**
* returns true if it is a touch device
*/
export function isTouchDevice() {
	return 'ontouchstart' in window || navigator.maxTouchPoints;
}

/**
* returns a random integer between min and max (inclusive)
*/
export function random(min, max) {
	return Math.floor( Math.random() * ( max - min + 1 ) + min );
}

/**
* add commas to number
*/
export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
* returns the distance between the top of el and the bottom of the viewport
*/
export function heightInView(el) {
	return scrollTop() + viewport().height - getPosition(el).y;
}


export function isInView(el) {
	scrollTop = scrollTop();
	return heightInView(el, scrollTop) >= 0 && getPosition(el).y + el.getBoundingClientRect().height - scrollTop > 0;
}

export function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}
