import Swiper from 'swiper';
import { TweenLite, TimelineMax } from 'gsap/TweenMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { getClosest, getPosition, scrollTop, viewport, pageHeight } from './helpers';

export default class Milestones {
	constructor(el) {
		this.el = el;
		this.widget = this.el.querySelector('.milestones__widget');
		this.milestoneEvents = [...this.el.querySelectorAll('.milestones__event')];
		this.milestoneEventContainers = [...this.el.querySelectorAll('.milestones__event__container')];
		this.milestoneExpandBtns = [...this.el.querySelectorAll('.milestones__event__expand__container')];
		this.expandBtnHeight = 80;

		this.init();
		this.events();
	}

	init() {
		this.swiper = new Swiper ('.milestones__container', {

			navigation: {
				nextEl: '.milestones__nav__btn--next',
				prevEl: '.milestones__nav__btn--prev',
			},
			
			slidesPerView: 3,
			spaceBetween: 30,
			threshold: 10,

			breakpoints: {
				640: {
					slidesPerView: 1,
				},

				1024: {
					slidesPerView: 2,
				}
			},

			on: {
				init: () => {
					this.resizeHandle();
					this.widget.classList.add('ready');
				}
			}

		});

	}

	events() {
		window.addEventListener('resize', this.resizeHandle.bind(this));

		this.milestoneExpandBtns.forEach(btn => {
			const event = getClosest(btn, '.milestones__event');
			const container = event.querySelector('.milestones__event__container');
			const containerHeight = container.getBoundingClientRect().height;
			const content = event.querySelector('.milestones__event__content');
			const img = event.querySelector('.milestones__event__img');
			const expandedHeight = content.getBoundingClientRect().height + img.getBoundingClientRect().height + this.expandBtnHeight;

			btn.addEventListener('click', e => {

				if (event.classList.contains('expanded')) {
					event.classList.remove('expanded');
					TweenLite.to(container, 0.5, { height: containerHeight, ease: Power3.easeInOut });

				} else {
					event.classList.add('expanded');
					TweenLite.to(container, 0.5, { height: expandedHeight, ease: Power3.easeInOut });
				}
			});
		});
	}

	goToEvent(id) {

	}

	resizeHandle() {
		// let maxHeight = 0;

		this.milestoneEvents.forEach(el => {

			el.classList.remove('long');
			el.classList.remove('expanded');
			
			const container = el.querySelector('.milestones__event__container');
			const containerHeight = container.getBoundingClientRect().height;
			const content = el.querySelector('.milestones__event__content');
			const img = el.querySelector('.milestones__event__img');
			const contentHeight = content.getBoundingClientRect().height + img.getBoundingClientRect().height;

			container.removeAttribute('style');

			if (contentHeight > containerHeight) {
				el.classList.add('long');
			}


		});

		// this.milestoneEventContainers.forEach(el => {
		// 	let elHeight = el.getBoundingClientRect().height;

		// 	if (elHeight > maxHeight) {
		// 		maxHeight = elHeight;
		// 	}
		// });

		// this.milestoneEventContainers.forEach(el => {
		// 	TweenLite.set(el, { height: maxHeight });
		// });
	}

}