import { TweenLite, TimelineMax } from 'gsap/TweenMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { getPosition, viewport } from './helpers';

export default class Staff {
	constructor(el) {
		this.el = el;

		this.target = this.el.getAttribute('data-target');
		this.bio = document.querySelector(`.member__bio[data-index="${this.target}"]`);

		this.header = document.querySelector('.header');

		this.isOpen = false;

		this.init();
		this.events();
	}

	init() {

	}

	events() {
		if (this.target && this.bio) {
			const bottomCloseBtn = this.bio.querySelector('.member__close-btn');
			this.el.addEventListener('click', this.toggle.bind(this));

			const closeBtn = this.bio.querySelector('.member__bio__close');

			if (closeBtn) {
				closeBtn.addEventListener('click', this.close.bind(this));
			}

			if (bottomCloseBtn) {
				bottomCloseBtn.addEventListener('click', this.close.bind(this));
			}
		}
	}

	toggle() {
		this.isOpen ? this.close() : this.open();
	}

	open() {
		this.closeAll();
		this.el.classList.add('active');
		this.bio.classList.add('active');
		this.isOpen = true;
		App.stickySidebar.updateSticky();

		const headerHeight = this.header.getBoundingClientRect().height;

		let y = getPosition(this.bio).y - headerHeight - 30;

		if (viewport().width > 640) {
			y = getPosition(this.el).y - headerHeight - 30;
		}

		TweenLite.to(window, 1, { ease: Power3.easeInOut, scrollTo: { y: y, autoKill: false } });
	}

	close() {
		this.el.classList.remove('active');
		this.bio.classList.remove('active');
		this.isOpen = false;
		App.stickySidebar.updateSticky();

		const headerHeight = this.header.getBoundingClientRect().height;

		const y = getPosition(this.el).y - headerHeight - 30;
		TweenLite.to(window, 1, { ease: Power3.easeInOut, scrollTo: { y: y, autoKill: false } });
	}

	closeAll() {
		App.StaffMembers.forEach(obj => {
			if (obj.isOpen) {
				obj.close();
			}
		});
	}

}