import Accordion from './accordion';

export default class AccordionGroup {
	constructor(el) {
		this.el = el;

		this.accordions = [...this.el.querySelectorAll('.accordion')];
		this.Accordions = [];

		this.init();
	}

	init() {
		if (this.accordions.length > 0) {
			this.accordions.forEach(el => {
				this.Accordions.push(new Accordion(el, this));
			});
		}
	}

	closeAll() {
		this.Accordions.forEach(obj => {
			obj.close();
		});
	}
}