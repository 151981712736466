import { getPosition, scrollTop, viewport, getClosest } from './helpers';
import throttle from 'lodash.throttle';

export default class Sidebar {
	constructor(el) {

		this.sidebar = el;
		this.nav = this.sidebar.querySelector('.sidebar__main-nav');
		this.header = document.querySelector('.header');
		this.pageContainer = document.querySelector('.page-container');
		this.menuItems = [...document.querySelectorAll('.sidebar__main-nav > li')];

		this.init();
		this.events();
	}

	init() {

	}

	events() {

		if (this.menuItems.length > 0) {
			this.menuItems.forEach(item => {
				let subNav = item.querySelector('.sidebar__sub-nav');
				let chevron = item.querySelector('.fa-chevron-down');

				if (subNav && chevron) {

					chevron.addEventListener('click', e => {
						App.stickySidebar.updateSticky();
						if (item.classList.contains('open')) {
							item.classList.remove('open');
						} else {
							this.closeAll();
							item.classList.add('open');
						}
					});
				}
			});
		}
	}

	closeAll() {
		this.menuItems.forEach(item => {
			let subNav = item.querySelector('.sidebar__sub-nav');
			let chevron = item.querySelector('.fa-chevron-down');

			if (subNav && chevron) {
				item.classList.remove('open');
			}
		});
	}

}