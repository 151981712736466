import { TweenLite, TimelineMax } from 'gsap/TweenMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { getPosition, scrollTop, viewport, pageHeight } from './helpers';
import throttle from 'lodash.throttle';

export default class Home {

	constructor() {
		this.paginationContainer = document.querySelector('.hp__pagination');
		this.paginationBullets = [...document.querySelectorAll('.hp__pagination__bullet')];
		this.slides = [...document.querySelectorAll('.hp__slide')];
		this.slidesContainer = document.querySelector('.hp__slides');
		this.header = document.querySelector('.header');

		this.init();
		this.events();
	}

	init() {
		this.scrollHandle();
	}

	events() {
		this.scrollHandle = throttle(this.scrollHandle, 16).bind(this);
		window.addEventListener('scroll', this.scrollHandle);
		window.addEventListener('resize', this.scrollHandle);

		if (this.paginationBullets) {
			this.paginationBullets.forEach(bullet => {
				bullet.addEventListener('click', e => {
					this.bulletClickHandler(bullet);
				});
			});
		}

	}

	scrollHandle() {
		if (!this.paginationContainer) {
			return;
		}

		const st = scrollTop();

		// sticky pagination

		const paginationHeight = this.paginationContainer.getBoundingClientRect().height;
		const paginationPosition = getPosition(this.paginationContainer).y;
		const viewportHeight = viewport().height;
		const headerHeight = this.header.getBoundingClientRect().height + 200;

		if ((paginationPosition - st) * 2 + paginationHeight <= viewportHeight) {
			this.paginationContainer.classList.add('sticky');

		} else {
			this.paginationContainer.classList.remove('sticky');
		}

		if ((viewportHeight + st - getPosition(this.slidesContainer).y - this.slidesContainer.getBoundingClientRect().height + 120) * 2 + paginationHeight > viewportHeight) {
			this.paginationContainer.classList.add('sticky-bottom');
		} else {
			this.paginationContainer.classList.remove('sticky-bottom');
		}

		// scroll spy

		if (this.paginationBullets) {
			this.paginationBullets.forEach(bullet => {
				const slide = this.getSlide(bullet);

				if (slide) {
					let slideTop = getPosition(slide).y;
					let slideHeight = slide.getBoundingClientRect().height;
					let isInView = st >= slideTop - headerHeight && st < slideTop + slideHeight - headerHeight;

					if ( isInView ) {
						bullet.classList.add('active');
					} else {
						bullet.classList.remove('active');
					}
				}
			});
		}

		if (st + viewportHeight === pageHeight()) {
			this.paginationBullets.forEach(bullet => {
				bullet.classList.remove('active');
			});

			this.paginationBullets[this.paginationBullets.length - 1].classList.add('active');
		}

	}

	bulletClickHandler(bullet) {
		const targetSlide = this.getSlide(bullet);

		if (targetSlide) {
			const y = getPosition(targetSlide).y;
			TweenLite.to(window, 1, { ease: Power3.easeInOut, scrollTo: { y: y, autoKill: false } });
		}
	}

	getSlide(bullet) {
		const target = bullet.getAttribute('data-target');
		const targetSlide = document.querySelector(`.hp__slide[data-index="${target}"]`);
		return targetSlide;
	}


}